import { roundTo } from './helper';

export const calculateTourTicketDepositAmount = ({ ticket, initialPaymentTypeID = 1, ticketDepositAmount }) => {
    const basePrice = Number(ticket.price);
    const optionalUpgradeTotal = ticket.optionalUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.price);
    }, 0);
    const requiredUpgradeTotal = ticket.requiredUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.price);
    }, 0);

    if (initialPaymentTypeID === 2) {
        return Number(ticketDepositAmount).toFixed(2);
    } else if (initialPaymentTypeID === 3) {
        return roundTo(
            (basePrice || 0 + optionalUpgradeTotal || 0 + requiredUpgradeTotal || 0) *
                (Number(ticketDepositAmount) / 100),
            2
        ).toFixed(2);
    }
};

export const calculateTourTicketBasePrice = (ticket) => {
    const basePrice = Number(ticket.price);
    const optionalUpgradeTotal = ticket.optionalUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.price);
    }, 0);
    const requiredUpgradeTotal = ticket.requiredUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.price);
    }, 0);

    return roundTo(basePrice + optionalUpgradeTotal + requiredUpgradeTotal, 2).toFixed(2);
};

export const calculateTourTicketTaxPrice = (ticket) => {
    const baseTax = Number(ticket.salesTax);
    const optionalUpgradeTax = ticket.optionalUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.salesTax);
    }, 0);
    const requiredUpgradeTax = ticket.requiredUpgrades?.reduce((sum, upgrade) => {
        return sum + Number(upgrade.salesTax);
    }, 0);
    return roundTo(baseTax + optionalUpgradeTax + requiredUpgradeTax, 2).toFixed(2);
};

export const calculateTourTicketFullPrice = (ticket, bookingFee) => {
    return roundTo(
        Number(calculateTourTicketBasePrice(ticket)) + Number(bookingFee) + Number(calculateTourTicketTaxPrice(ticket)),
        2
    ).toFixed(2);
};

export const calculateTourTicketDepositCost = (tickets, selectedTour) => {
    let sum = 0;
    for (let ticket of tickets) {
        sum += Number(
            calculateTourTicketDepositAmount({
                ticket,
                ticketDepositAmount: selectedTour.ticketDepositAmount,
                initialPaymentTypeID: selectedTour.initialPaymentTypeID,
            })
        );
    }
    return roundTo(sum, 2).toFixed(2);
};

export const calculateGrandTotal = ({ tickets, tripSetting, includeSurcharge = true }) => {
    const surchargeAmount = includeSurcharge
        ? Number(calculateSurchargeAmount({ tickets, tripSetting })).toFixed(2)
        : 0;
    return Number(calculateAllTicketsPrice(tickets, tripSetting.bookingFee) + surchargeAmount).toFixed(2);
};

export const calculateAllTicketsPrice = (tickets, bookingFee) => {
    let sum = 0;
    for (let ticket of tickets) {
        sum += Number(calculateTourTicketFullPrice(ticket, bookingFee));
    }
    return roundTo(sum, 2).toFixed(2);
};

export const calculateSurchargeAmount = ({ amount = null, tickets, tripSetting }) => {
    if (tripSetting.creditCardSurchargePercentage) {
        const creditCardSurchargePercentage = Number(tripSetting.creditCardSurchargePercentage) / 100;

        return roundTo(
            (amount ? amount : Number(calculateAllTicketsPrice(tickets, tripSetting.bookingFee))) *
                creditCardSurchargePercentage,
            2
        ).toFixed(2);
    } else return 0;
};

export const calculateAmountToBeCharged = ({
    tickets,
    initialPaymentTypeID,
    ticketDepositAmount,
    tripSetting,
    selectedPaymentType = '',
}) => {
    if (initialPaymentTypeID === 1 || selectedPaymentType === 'full') {
        return (
            Number(calculateAllTicketsPrice(tickets, tripSetting.bookingFee)) +
            Number(calculateSurchargeAmount({ tickets, tripSetting }))
        ).toFixed(2);
    } else if (initialPaymentTypeID === 2) {
        const amount = Number(ticketDepositAmount * tickets.length);
        return (amount + Number(calculateSurchargeAmount({ amount, tickets, tripSetting }))).toFixed(2);
    } else {
        let amount = 0;
        for (let ticket of tickets) {
            amount += Number(calculateTourTicketDepositAmount({ ticket, ticketDepositAmount, initialPaymentTypeID }));
        }
        return (Number(amount) + Number(calculateSurchargeAmount({ amount, tickets, tripSetting }))).toFixed(2);
    }
};
