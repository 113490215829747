import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const TourTicketSelection = ({
    tripSetting,
    availableTickets,
    quantities,
    setQuantities,
    tourContractAndRouteInfo,
    selectedTour,
}) => {
    const [totalTickets, setTotalTickets] = useState(0);
    const [quantityRemaining, setQuantityRemaining] = useState(null);
    const [quantityExceededMessage, setQuantityExceededMessage] = useState(false);

    useEffect(() => {
        (() => {
            if (selectedTour.ticketQuantityRemaining) {
                setQuantityRemaining(selectedTour.ticketQuantityRemaining);
            } else {
                setQuantityRemaining(tourContractAndRouteInfo.ticketLimit);
            }

            const quantityMap = {};
            for (let t of availableTickets) {
                quantityMap[t.tourTicketID] = {
                    ticketType: t.ticketType,
                    available: t.numRemaining,
                    selected: 0,
                    price: t.price,
                    salesTax: t.salesTax,
                    salesTaxID: t.salesTaxID,
                    availableTicketID: t.id,
                };
            }
            setTotalTickets(0);
            setQuantityExceededMessage(false);
            setQuantities(quantityMap);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTickets, setQuantities, selectedTour]);

    const handleQuantityChange = (tourTicketID, operation) => {
        const quantityCopy = { ...quantities };
        if (quantityCopy[tourTicketID]?.available < 1) return;
        if (operation === 'plus') {
            if (totalTickets < quantityRemaining) {
                quantityCopy[tourTicketID] = {
                    ...quantityCopy[tourTicketID],
                    selected: Math.min(quantityCopy[tourTicketID].selected + 1, quantityCopy[tourTicketID].available),
                };
                setTotalTickets(totalTickets + 1);
            } else {
                setQuantityExceededMessage(true);
            }
        } else {
            const updateValue = quantityCopy[tourTicketID].selected - 1;
            quantityCopy[tourTicketID] = {
                ...quantityCopy[tourTicketID],
                selected: Math.max(updateValue, 0),
            };
            if (updateValue >= 0) {
                setTotalTickets(Math.max(totalTickets - 1, 0));
                setQuantityExceededMessage(false);
            }
        }

        setQuantities(quantityCopy);
    };

    return (
        <Card className="mx-1 mt-2 p-3" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
            <h4 className="mb-4" style={{ color: `${tripSetting.textColor}` }}>
                Select Tickets
            </h4>
            {quantityRemaining && quantityRemaining < 20 && (
                <p className="font-italic">{`Only ${quantityRemaining} Tickets Remaining`}</p>
            )}
            {quantityExceededMessage && <p className="text-danger">Maximum quantity exceeded.</p>}
            {availableTickets &&
                availableTickets.map((at) => {
                    const quantityObj = quantities[at.tourTicketID];
                    return (
                        <div key={at.id} className="d-flex my-1">
                            <Row className="w-100">
                                <Col lg={8} md={8} sm={8} xs={8}>
                                    <div>
                                        <p
                                            className="bold mr-2"
                                            style={{ color: `${tripSetting.textColor}`, margin: '0px 4px' }}
                                        >{`${at.ticketType}`}</p>
                                    </div>

                                    <span
                                        className={quantityObj?.available < 1 ? 'font-italic' : ''}
                                    >{`${quantityObj?.available > 0 ? `$${at.price}` : 'Out of Stock'}`}</span>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                >
                                    <i
                                        className="fas fa-minus-circle"
                                        role="button"
                                        style={{ opacity: `${quantityObj?.available < 1 ? '0.5' : ''}` }}
                                        onClick={() => handleQuantityChange(at.tourTicketID, 'minus')}
                                    />
                                    <p className="text-align-center large" style={{ margin: '0px' }}>
                                        {quantityObj?.selected}
                                    </p>
                                    <i
                                        className="fas fa-plus-circle"
                                        role="button"
                                        style={{ opacity: `${quantityObj?.available < 1 ? '0.5' : ''}` }}
                                        onClick={() => handleQuantityChange(at.tourTicketID, 'plus')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    );
                })}
        </Card>
    );
};

export default TourTicketSelection;
