import React, { useEffect, useState } from 'react';
import Loading from '../Loading';
import TicketPricingSummary from '../TicketPricingSummary';
import { useForm } from 'react-hook-form';
import { createTransactionService, getCountriesService, getStatesService } from '../../services/transactionsService';
import { getTicketPurchaseOrderDetailsService } from '../../services/ticketPurchaseOrderService';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';

import './index.scss';
import BillingPaymentInformation from '../BillingPaymentInformation';
import { makeBookingPortalPaymentService } from '../../services/authorizeNetService';
import { calculateSurchargeAmount } from '../../utils/ticketPriceCalculations';
import TourConfirmation from '../TourConfirmation';
import moment from 'moment';
const TourPaymentLanding = (props) => {
    const ticketPurchaseOrderID = props.match?.params?.ticketPurchaseOrderID;

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError } = useForm();

    const [selectedTour, setSelectedTour] = useState({});
    const [tripSetting, setTripSetting] = useState({});
    const [tickets, setTickets] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [ip, setIP] = useState('');
    const [errors, setErrors] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState({
        isPaymentConfirmed: false,
    });

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const fetchedStates = await getStatesService();
            setStates(fetchedStates?.data);
            const fetchedCountries = await getCountriesService();
            setCountries(fetchedCountries?.data);
            const { data: fetchedTPODetails } = await getTicketPurchaseOrderDetailsService(ticketPurchaseOrderID);
            setSelectedTour(fetchedTPODetails?.tourData);
            setTripSetting(fetchedTPODetails?.accountSettings);
            setTickets(fetchedTPODetails?.ticketsData);
            setLoading(false);
            if (!fetchedTPODetails || !fetchedTPODetails?.tourData) {
                setLoading(false);
                await swal({
                    title: 'Error',
                    text: 'Ticket Purchase Order not found',
                    icon: 'error',
                });
                return;
            }
            if (fetchedTPODetails?.tourData?.amountDue === 0) {
                setLoading(false);
                await swal({
                    title: 'Payment Status',
                    text: 'You have paid for the tour already.',
                    icon: 'success',
                });
                return;
            }
        })();
    }, []);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    };

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress();
    }, []);

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                if (selectedTour.amountDue === 0) {
                    setLoading(false);
                    return swal({
                        title: 'Payment Status',
                        text: 'You have paid for the tour already.',
                        icon: 'success',
                    });
                }
                setLoading(true);

                // Check Payment Data
                const errorArr = [];
                if (!data.firstName) errorArr.push('firstName');
                if (!data.lastName) errorArr.push('lastName');
                if (!data.email) errorArr.push('email');
                if (!data.street) errorArr.push('street');
                if (!data.city) errorArr.push('city');
                if (!data.state) errorArr.push('state');
                if (!data.zip) errorArr.push('zip');
                if (!data.cardExpiration) errorArr.push('cardExpiration');
                if (!data.cardNumber) errorArr.push('cardNumber');
                if (!data.cvv) errorArr.push('cvv');
                setErrors([...errorArr]);

                if (!errorArr.length) {
                    if (data && data.cardNumber && data.cardNumber.length < 15) {
                        setLoading(false);
                        return swal({
                            title: 'Card Number Error',
                            text: 'Please enter a valid Card Number',
                            icon: 'error',
                        });
                    }

                    const surchargeAmount = calculateSurchargeAmount({
                        amount: selectedTour.amountDue,
                        tickets,
                        tripSetting,
                    });

                    const transactionRequestData = {
                        ...data,
                        ticketPurchaseOrderID: ticketPurchaseOrderID,
                        amount: Number(selectedTour.amountDue) + Number(surchargeAmount),
                        surchargeAmount: surchargeAmount > 0 ? surchargeAmount : null,
                        transactionTypeID: 3,
                        companyID: tripSetting.companyID,
                        date: moment().format('YYYY-MM-DD'),
                        generalLedgerCodeID: tripSetting.defaultGeneralLedgerCodeID || null,
                    };
                    let transactionRequest = await createTransactionService(transactionRequestData);
                    const { transaction, surchargeTransaction = null } = transactionRequest.data;

                    const paymentRequestData = {
                        ...transactionRequestData,
                    };
                    paymentRequestData.companyID = tripSetting.companyID;
                    paymentRequestData.orderID = transaction.id;
                    paymentRequestData.ecomind = 'E';
                    paymentRequestData.customerID = ip;
                    if (surchargeTransaction) paymentRequestData.surchargeTransactionID = surchargeTransaction.id;

                    let response = await makeBookingPortalPaymentService(paymentRequestData, tripSetting.companyID);

                    response = response.data;
                    if (typeof response == 'undefined') {
                        window.swal(
                            'Transaction Failed',
                            "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.",
                            'error'
                        );
                        setLoading(false);
                        return;
                    } else if (response.errors && response.errors.length > 0) {
                        swal({ title: 'Transaction Declined', text: response.errors[0].msg, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.error) {
                        swal({ title: 'Transaction Declined', text: response.error, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.Result === 'Declined') {
                        swal({ title: 'Transaction Declined', text: response.Status, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.code === 'A') {
                        setLoading(false);
                        setPaymentStatus({
                            id: transaction.id,
                            amount: transaction.amount,
                            isPaymentConfirmed: true,
                            emailSent: response.emailSent,
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                console.log('error: ', e);
                swal({
                    title: 'There was an error processing your tickets. Please try again.',
                    icon: 'error',
                });
                setLoading(false);
                return;
            }
        })();
    };

    return loading ? (
        <Loading loading={loading} />
    ) : (
        <div className="background-box-color p-3">
            <div className="background-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                <Container fluid>
                    {paymentStatus.isPaymentConfirmed ? (
                        <TourConfirmation
                            selectedTour={selectedTour}
                            tripSetting={tripSetting}
                            tourContractDescription={selectedTour?.tourContractDescription}
                            formState={paymentStatus}
                        />
                    ) : (
                        <Row>
                            <Col md={8} xs={12} className="d-flex justify-content-center">
                                <div className="w-100 payment-form-main">
                                    <form
                                        className="payment-form-wrapper"
                                        onSubmit={(e) => {
                                            handleSubmitPayment(e);
                                        }}
                                    >
                                        <BillingPaymentInformation
                                            register={register}
                                            errors={errors}
                                            setErrors={setErrors}
                                            tripSetting={tripSetting}
                                            states={states}
                                            countries={countries}
                                            isTourOutstandingPayment={true}
                                        />

                                        <div>
                                            <p className="payment-form-required-title text-white mt-2">
                                                *Field Required
                                            </p>
                                        </div>
                                        <div className="button-wrapper mt-3">
                                            <div className="button-of-first">
                                                <Button
                                                    className="mb-2"
                                                    disabled={
                                                        tickets.some((ticket) => ticket?.errors?.length) ||
                                                        errors.length
                                                    }
                                                    onClick={handleSubmitPayment}
                                                    style={{
                                                        backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                                        borderColor: 'transparent',
                                                    }}
                                                >
                                                    <i className="fas fa-lock  mr-2 "></i> SUBMIT PAYMENT
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                            <TicketPricingSummary
                                tripSetting={tripSetting}
                                selectedTour={selectedTour}
                                tickets={tickets}
                                width={width}
                                isTourOutstandingPayment={true}
                            />
                        </Row>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default TourPaymentLanding;
