import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formatDateRange } from '../../utils/helper';
import {
    calculateGrandTotal,
    calculateSurchargeAmount,
    calculateTourTicketDepositAmount,
    calculateTourTicketDepositCost,
    calculateTourTicketFullPrice,
    calculateTourTicketTaxPrice,
} from '../../utils/ticketPriceCalculations';

const TicketPricingSummary = ({
    selectedTour,
    tickets,
    tripSetting,
    width,
    isTourOutstandingPayment = false,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmount,
}) => {
    return (
        <>
            <Col md={4} xs={12} className="d-flex flex-column tour-details-main">
                <div
                    className="tour-details-box"
                    style={{
                        marginTop: `${width > 768 ? '80px' : '20px'}`,
                        columnGap: '20px',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: `${tripSetting.mainColor}`,
                            borderRadius: '10px',
                            padding: `${width > 768 ? '25px' : '20px'}`,
                        }}
                    >
                        <h4 className="mb-4 bold" style={{ color: `${tripSetting.textColor}` }}>
                            <Row className="w-100 align-items-center">
                                <Col
                                    lg={6}
                                    md={8}
                                    sm={12}
                                    xs={12}
                                    className="text-start mb-2 mb-sm-0"
                                    style={{ fontSize: '1rem' }}
                                >
                                    {selectedTour.routeDescription}
                                </Col>

                                <Col
                                    lg={6}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="text-start text-md-end"
                                    style={{ fontSize: '0.9rem' }}
                                >
                                    {formatDateRange(selectedTour.startDate, selectedTour.endDate)}
                                </Col>
                            </Row>
                        </h4>

                        <h5 className="mb-2" style={{ color: `${tripSetting.textColor}` }}>
                            Pricing
                        </h5>
                        {tickets.map((ticket) => {
                            return (
                                <div className="mb-2">
                                    <Row className="w-100">
                                        <Col lg={8} md={8} sm={8} xs={8}>
                                            <div>
                                                <p
                                                    className="bold mr-2"
                                                    style={{ color: `${tripSetting.textColor}`, margin: '0px 4px' }}
                                                >{`${ticket.ticketType}`}</p>
                                            </div>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span>{Number(ticket.price) > 0 ? `$${ticket.price}` : ''}</span>
                                            </p>
                                        </Col>
                                    </Row>

                                    {ticket.requiredUpgrades?.map((reqUpgrade) => (
                                        <Row key={reqUpgrade.id} className="w-100">
                                            <Col
                                                className="d-flex align-items-center justify-content-between"
                                                lg={8}
                                                md={8}
                                                sm={8}
                                                xs={8}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    {reqUpgrade.availableTicketUpgrade}
                                                </p>
                                            </Col>
                                            <Col
                                                className="d-flex align-items-center justify-content-end"
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    <span>
                                                        {Number(reqUpgrade.price) > 0 ? `$${reqUpgrade.price}` : ''}
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>
                                    ))}
                                    {ticket.optionalUpgrades?.map((optionalUpgrade) => (
                                        <Row className="w-100" key={optionalUpgrade.id}>
                                            <Col
                                                className="d-flex align-items-center justify-content-between"
                                                lg={8}
                                                md={8}
                                                sm={8}
                                                xs={8}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    {optionalUpgrade.availableTicketUpgrade}
                                                </p>
                                            </Col>
                                            <Col
                                                className="d-flex align-items-center justify-content-end"
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    <span>
                                                        {Number(optionalUpgrade.price) > 0
                                                            ? `$${optionalUpgrade.price}`
                                                            : ''}
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>
                                    ))}
                                    {Number(tripSetting.bookingFee) > 0 ? (
                                        <Row className="w-100">
                                            <Col className="d-flex align-items-center" lg={8} md={8} sm={8} xs={8}>
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    Booking Fee
                                                </p>
                                            </Col>
                                            <Col
                                                className="d-flex align-items-center justify-content-end"
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    <span>${Number(tripSetting.bookingFee).toFixed(2)}</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    ) : (
                                        ''
                                    )}
                                    {calculateTourTicketTaxPrice(ticket) > 0 ? (
                                        <Row className="w-100">
                                            <Col className="d-flex align-items-center" lg={8} md={8} sm={8} xs={8}>
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    Tax
                                                </p>
                                            </Col>
                                            <Col
                                                className="d-flex align-items-center justify-content-end"
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    <span>${calculateTourTicketTaxPrice(ticket)}</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    ) : (
                                        ''
                                    )}
                                    {selectedPaymentType === 'deposit' && (
                                        <Row className="w-100">
                                            <Col
                                                className="d-flex align-items-center justify-content-between"
                                                lg={8}
                                                md={8}
                                                sm={8}
                                                xs={8}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    Deposit Amount to be Charged
                                                </p>
                                            </Col>
                                            <Col
                                                className="d-flex align-items-center justify-content-end"
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                            >
                                                <p className="text-align-center" style={{ margin: '0px' }}>
                                                    <span>
                                                        {calculateTourTicketDepositAmount({
                                                            ticket,
                                                            initialPaymentTypeID: selectedTour.initialPaymentTypeID,
                                                            ticketDepositAmount: selectedTour.ticketDepositAmount,
                                                        })}
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>
                                    )}

                                    <Row className="w-100">
                                        <Col
                                            className="d-flex align-items-center justify-content-between"
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={8}
                                        >
                                            <p className="text-align-center bold" style={{ margin: '0px' }}>
                                                Total Ticket Cost
                                            </p>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span className="bold">{`$${calculateTourTicketFullPrice(ticket, tripSetting.bookingFee)}`}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                        {selectedTour.initialPaymentTypeID !== 1 ? (
                            <Row className="w-100">
                                <hr className="hr-line " />
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center bold" style={{ margin: '0px' }}>
                                        Total Price
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${calculateGrandTotal({ tickets, tripSetting, includeSurcharge: tripSetting.initialPaymentTypeID === 1 })}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                    </div>
                    <div
                        style={{
                            backgroundColor: `${tripSetting.mainColor}`,
                            borderRadius: '10px',
                            padding: `${width > 768 ? '25px' : '20px'}`,
                            marginTop: '15px',
                        }}
                    >
                        {!isTourOutstandingPayment && selectedTour?.initialPaymentTypeID !== 1 ? (
                            <Row>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <Form.Check
                                        label="Pay Deposit"
                                        name="payDeposit"
                                        type="radio"
                                        id="payBalanceDue"
                                        checked={selectedPaymentType === 'deposit'}
                                        onChange={() => setSelectedPaymentType('deposit')}
                                    />
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <Form.Check
                                        label="Pay Full Amount"
                                        name="payFullAmount"
                                        type="radio"
                                        id="payFullAmount"
                                        checked={selectedPaymentType === 'full'}
                                        onChange={() => setSelectedPaymentType('full')}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        {Number(tripSetting?.creditCardSurchargePercentage) > 0 ? (
                            <Row className="w-100">
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        {`Total Credit Card Surcharge (${tripSetting?.creditCardSurchargePercentage}%) ${selectedPaymentType === 'deposit' ? 'of Deposit' : ''} `}
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${calculateSurchargeAmount({ amount: selectedPaymentType === 'deposit' ? calculateTourTicketDepositCost(tickets, selectedTour) : null, tripSetting, tickets })}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}

                        {!isTourOutstandingPayment && (
                            <Row className="w-100">
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center bold" style={{ margin: '0px' }}>
                                        Payment Amount
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${paymentAmount}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        )}
                        {isTourOutstandingPayment && Number(selectedTour?.SurchargeFees) > 0 && (
                            <Row className="w-100">
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center bold" style={{ margin: '0px' }}>
                                        Paid Surcharge Fees
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${selectedTour?.SurchargeFees}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        )}
                        {isTourOutstandingPayment && (
                            <Row className="w-100">
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center bold" style={{ margin: '0px' }}>
                                        Amount Paid
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${selectedTour?.amountPaid}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        )}
                        {isTourOutstandingPayment && (
                            <Row className="w-100 mt-2">
                                <Col
                                    className="d-flex align-items-center justify-content-between"
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <p className="text-align-center bold" style={{ margin: '0px' }}>
                                        Remaining Balance{' '}
                                        {Number(tripSetting?.creditCardSurchargePercentage) > 0
                                            ? '(Excluding Surcharge)'
                                            : ''}
                                    </p>
                                </Col>
                                <Col
                                    className="d-flex align-items-center justify-content-end"
                                    lg={3}
                                    md={3}
                                    sm={3}
                                    xs={3}
                                >
                                    <p className="text-align-center" style={{ margin: '0px' }}>
                                        <span className="bold">{`$${selectedTour?.amountDue}`}</span>
                                    </p>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </Col>
        </>
    );
};

export default TicketPricingSummary;
