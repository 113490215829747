import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Image, Container, Card } from 'react-bootstrap';
import Loading from '../Loading';
import { formatDateRange } from '../../utils/helper';
import './index.scss';
import { getTourRouteVehicleAssignments } from '../../services/toursServices';
import TourCalendarSelection from '../TourCalendarSelection';
import TourInformationWindow from '../TourInformationWindow';
import TourTicketSelection from '../TourTicketSelection';
import TourPaymentForm from '../TourPaymentForm';
import TourConfirmation from '../TourConfirmation';

const TourLanding = (props) => {
    const tourRouteID = props.match?.params?.tourRouteID;
    const [loading, setLoading] = useState(true);
    const [tourVehicleAssignments, setTourVehicleAssignments] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedTour, setSelectedTour] = useState(null);
    const [tourContractAndRouteInfo, setTourContractAndRouteInfo] = useState(null);
    const [availableTourRouteTickets, setAvailableTourRouteTickets] = useState([]);
    const [availableSelectedTourTickets, setAvailableSelectedTourTickets] = useState([]);

    const [ticketUpgrades, setTicketUpgrades] = useState({
        available: [],
        required: [],
    });

    const [selectedTourTicketUpgrades, setSelectedTourTicketUpgrades] = useState({
        available: [],
        required: [],
    });

    const [quantities, setQuantities] = useState({});
    const [tripSetting, setTripSetting] = useState(null);
    const [formState, setFormState] = useState({ stage: 0, amount: '', id: '' });
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (tourVehicleAssignments.length) {
                try {
                    if (selectedEvent) {
                        const filteredVehicleAssignment = tourVehicleAssignments.find(
                            (tva) => tva.startDate === selectedEvent.startDate
                        );
                        setSelectedTour(filteredVehicleAssignment);
                    }
                } catch (e) {
                    console.log('e', e);
                    setLoading(false);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvent]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const allTourData = await getTourRouteVehicleAssignments(tourRouteID);
                setTourVehicleAssignments(allTourData?.data?.tourRouteVehicleAssignments);
                setTourContractAndRouteInfo(allTourData?.data?.tourContractAndRouteDetails);
                setTripSetting(allTourData?.data?.accountSettings[0]);
                setAvailableTourRouteTickets(allTourData?.data?.availableTickets);

                setTicketUpgrades({
                    available: allTourData?.data?.optionalTicketUpgrades,
                    required: allTourData?.data?.requiredTicketUpgrades,
                });

                setLoading(false);
            } catch (e) {
                console.log('e', e);
                setLoading(false);
            }
        })();
    }, [tourRouteID]);

    const handleSelectTour = (tourVehicleAssignment) => {
        setSelectedTour(tourVehicleAssignment);

        if (tourVehicleAssignment) {
            const ticketsAvailable = availableTourRouteTickets.filter(
                (trt) => trt.vehicleAssignmentID === tourVehicleAssignment.id
            );
            setAvailableSelectedTourTickets(ticketsAvailable);

            const { available, required } = ticketUpgrades;
            const filteredAvailableTicketUpgrades = available.filter(
                (atu) => atu.vehicleAssignmentID === tourVehicleAssignment.id
            );
            const filteredRequiredTicketUpgrades = required.filter(
                (rtu) => rtu.vehicleAssignmentID === tourVehicleAssignment.id
            );

            setSelectedTourTicketUpgrades({
                available: filteredAvailableTicketUpgrades,
                required: filteredRequiredTicketUpgrades,
            });
        }
    };

    const selectTourForm = () => (
        <Row className="d-flex" style={{ minHeight: '80vh' }}>
            <Col xl={8} lg={12} md={12} sm={12} xs={12} className="d-flex-column p-1">
                <TourCalendarSelection
                    data={tourVehicleAssignments}
                    tripSetting={tripSetting}
                    setSelectedTour={handleSelectTour}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                />
                {selectedTour ? (
                    <Card className="card-wrapper p-4 mt-4" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                        <Card.Body style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            <h4 className="text-center" style={{ color: `${tripSetting.textColor}` }}>
                                Selected Trip
                            </h4>

                            <div className="d-flex flex-wrap list-div mt-2 tour-selected" style={{ minHeight: '60px' }}>
                                <div className="col-12 col-md-8 m-auto">
                                    <div className="color-line"></div>
                                    <div className="card-l text-center text-md-left">
                                        <span className="card-lt">{selectedTour.routeDescription}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 card-r text-center text-md-right mt-2 mt-md-0">
                                    <div className="card-r cr-card">
                                        <span className="card-lt" style={{ color: `${tripSetting.textColor}` }}>
                                            {selectedEvent
                                                ? formatDateRange(selectedEvent.startDate, selectedEvent.endDate)
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                ) : (
                    ''
                )}
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12} className={width < 1200 ? `p-1` : ''}>
                {selectedTour ? (
                    <div className={`${width < 1200 ? 'mt-4' : ''}`}>
                        <div>
                            <TourInformationWindow
                                selectedTour={selectedTour}
                                tourContractAndRouteInfo={tourContractAndRouteInfo}
                                tripSetting={tripSetting}
                            />
                            <TourTicketSelection
                                selectedTour={selectedTour}
                                tripSetting={tripSetting}
                                availableTickets={availableSelectedTourTickets}
                                quantities={quantities}
                                setQuantities={setQuantities}
                                tourContractAndRouteInfo={tourContractAndRouteInfo}
                            />
                        </div>
                        <div className="button-wrapper mt-3 mx-5">
                            <div className="button-of-first">
                                <Button
                                    className="mb-2"
                                    disabled={!Object.values(quantities).some((q) => q.selected > 0)}
                                    onClick={() => {
                                        setFormState({ ...formState, stage: 1 });
                                        scrollTo({ top: 0, behavior: 'instant' });
                                    }}
                                    style={{
                                        backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                        borderColor: 'transparent',
                                    }}
                                >
                                    PROCEED TO CHECKOUT
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </Col>
        </Row>
    );

    const formatTicketQuantitiesForPayment = () =>
        Object.entries(quantities)
            .map(([key, val]) => ({
                ...val,
                tourTicketID: Number(key),
            }))
            .filter((q) => Number(q.selected) > 0);

    const paymentForms = () => {
        return (
            <TourPaymentForm
                tripSetting={tripSetting}
                selectedTour={selectedTour}
                ticketQuantities={formatTicketQuantitiesForPayment()}
                ticketUpgrades={selectedTourTicketUpgrades}
                width={width}
                setFormState={setFormState}
            />
        );
    };

    const getStageContents = (stage) => {
        if (stage === 0) return selectTourForm();
        else if (stage === 1) return paymentForms();
        else
            return TourConfirmation({
                tripSetting,
                tourContractDescription: tourContractAndRouteInfo?.tourContractDescription,
                formState,
                selectedTour,
            });
    };

    return !loading && tripSetting ? (
        <div>
            <div className="top-header">
                <div
                    className="top-header-box d-flex flex-wrap justify-content-between align-items-center p-3"
                    style={{ backgroundColor: `${tripSetting.sectionColor}` }}
                >
                    <div className="tour-title text-white d-flex flex-wrap align-items-center col-12 col-md-6">
                        {tripSetting && tripSetting.tourCompanyLogoUrl ? (
                            <Image src={tripSetting.tourCompanyLogoUrl} className="images-logo m-0 p-0" />
                        ) : (
                            ''
                        )}
                        <div
                            className="ml-md-3 mt-2 mt-md-0"
                            style={{ fontSize: '28px', color: `${tripSetting.headerTextColor}` }}
                        >
                            {tourContractAndRouteInfo?.tourRouteName}
                        </div>
                    </div>

                    <div className="col-12 col-md-6 text-md-right text-center mt-3 mt-md-0">
                        {[1, 2].includes(formState.stage) ? (
                            <Button
                                className="btn-responsive"
                                style={{
                                    backgroundColor: `${tripSetting.buttonColor}`,
                                    padding: '8px 16px',
                                    fontSize: '14px',
                                }}
                                onClick={() =>
                                    setFormState({
                                        ...formState,
                                        stage: 0,
                                    })
                                }
                            >
                                BACK TO TOUR SELECTION
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="background-box-color p-3">
                    <div className="background-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                        <Container fluid>{getStageContents(Number(formState.stage))}</Container>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end p-4">
                <Image
                    src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'}
                    className="m-0 p-0"
                />
            </div>
        </div>
    ) : (
        <Loading loading={loading} />
    );
};

export default React.memo(TourLanding, () => true);
