import React from 'react';
import { formatCurrency } from '../../utils/helper';
import { Image } from 'react-bootstrap';

const TourConfirmation = ({ tripSetting, selectedTour, tourContractDescription, formState }) => {
    const getTripDates = () =>
        selectedTour.startDate === selectedTour.endDate
            ? selectedTour.startDate
            : `${selectedTour.startDate} - ${selectedTour.endDate}`;

    return (
        <div className="background-box-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
            <p className="tour-title text-white">{tourContractDescription}</p>
            <div
                className="light-gray-bg  w-100 text-center inner-box"
                style={{ backgroundColor: `${tripSetting.mainColor}` }}
            >
                <div className="images-logo">
                    <Image src={tripSetting.tourCompanyLogoUrl} className="m-0 p-0" />
                </div>
                <div className="card-payment-box" style={{ backgroundColor: `${tripSetting.textColor}` }}>
                    <i className="fa fa-credit-card-alt text-dark mx-2" />
                    <span className="payment-title">Payment Success</span>
                </div>
                <i className="fa fa-thumbs-up text-dark fa-4x mt-3" />
                <h3 className="title-processed mb-2 mt-2" style={{ color: `${tripSetting.textColor}` }}>
                    Your Payment has been successfully processed
                </h3>
                {formState.emailSent && <h4>Additional details have been sent to your email.</h4>}
                <p className="mb-0 mt-3">Confirmation Number</p>
                <h4 className="title-amount mb-2" style={{ color: `${tripSetting.textColor}` }}>
                    {formState.id}
                </h4>
                <p className="mb-0 mt-3">Trip Date(s)</p>
                <h4 className="title-amount mb-2" style={{ color: `${tripSetting.textColor}` }}>
                    {getTripDates()}
                </h4>
                <p className="mb-0 mt-3">Amount Paid</p>
                <h4
                    className="title-amount mb-5 pb-5"
                    style={{ color: `${tripSetting.textColor}` }}
                >{`$${formatCurrency(formState.amount)}`}</h4>
            </div>
        </div>
    );
};

export default TourConfirmation;
