import "./SeatMap.css";
import { Seat } from "./Seat";
import {
  TSeatMapProps,
  TSeat,
  TSeatMapGrid,
  TSeatMapArea,
  EnumSeatMapLegendPosition,
} from "./SeatMap.type";
import { SeatIcon } from "./SeatIcon";
import { DriverSeat } from "./DriverSeat";

export const SeatMap = ({
  layout,
  handleSeatClick,
  selectedSeatIds,
  seatIcon,
  selectedSeatIcon,
  reservedSeatIcon,
  driverSeatIcon,
  seatMapLegendPostion = EnumSeatMapLegendPosition.BOTTOM,
}: TSeatMapProps) => {
  const renderSeatAreas = (grids: TSeatMapGrid[], maxSeatPerRow: number) => {
    return grids.map((grid: any, gridIdx: number) =>
      renderSeatGrids(gridIdx, grid.seats, maxSeatPerRow)
    );
  };

  const renderSeatGrids = (
    gridIdx: number,
    seats: TSeat[],
    maxSeatPerRow: number
  ) => {
    const gridRows: any = new Map();

    for (let i = 1; i <= maxSeatPerRow; i++) {
      gridRows.set(i, <span key={i}>&nbsp;</span>);
    }

    seats.forEach((seat: any, seatIdx: number) => {
      if (!seat.isEmpty) {
        gridRows.set(
          seat.gridSeatNum,
          <Seat
            seat={seat}
            selectedSeatIds={selectedSeatIds}
            handleSeatClick={handleSeatClick}
            seatIcon={seatIcon}
            selectedSeatIcon={selectedSeatIcon}
            reservedSeatIcon={reservedSeatIcon}
            driverSeatIcon={driverSeatIcon}
          />
        );
      }
    });

    return (
      <div
        key={gridIdx}
        className="grid-container"
        style={{ gridTemplateColumns: "45px ".repeat(maxSeatPerRow) }}
      >
        {Array.from(gridRows.values())}
      </div>
    );
  };

  const getSeatMapPosition = (
    position: EnumSeatMapLegendPosition = EnumSeatMapLegendPosition.BOTTOM
  ): any => {
    let flexDirection: string;

    switch (position) {
      case EnumSeatMapLegendPosition.TOP:
        flexDirection = "column-reverse";
        break;
      case EnumSeatMapLegendPosition.RIGHT:
        flexDirection = "row";
        break;
      case EnumSeatMapLegendPosition.BOTTOM:
        flexDirection = "column";
        break;
      case EnumSeatMapLegendPosition.LEFT:
        flexDirection = "row-reverse";
        break;
      default:
        flexDirection = "row";
        break;
    }

    return flexDirection;
  };

  const isLeftRightSeatLegend = (position: EnumSeatMapLegendPosition) => {
    return [
      EnumSeatMapLegendPosition.LEFT,
      EnumSeatMapLegendPosition.RIGHT,
    ].includes(position);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: getSeatMapPosition(seatMapLegendPostion),
      }}
    >
      <div className="seat-map-container">
        {layout.areas.map((area: TSeatMapArea, index: number) => (
          <div className="area-name" key={`area-${index}`}>
            <DriverSeat />
            {renderSeatAreas(area.grids, layout.maxSeatPerRow)}
            {area.areaName && <h3 className="text-center">{area.areaName}</h3>}
          </div>
        ))}
      </div>
      {seatMapLegendPostion !== EnumSeatMapLegendPosition.HIDE ? 
      <div
        style={{
          display: "flex",
          width: "90%",
          flexDirection: isLeftRightSeatLegend(seatMapLegendPostion)
            ? "column"
            : "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", margin: '5px' }}>
          {!reservedSeatIcon && <SeatIcon state="reserved" />}
          {reservedSeatIcon}
          Reserved
        </div>
        <div style={{ display: "flex", alignItems: "center", margin: '5px'  }}>
          {!seatIcon && <SeatIcon />}
          {seatIcon}
          Available
        </div>
        <div style={{ display: "flex", alignItems: "center", margin: '5px'  }}>
          {!selectedSeatIcon && <SeatIcon state="selected" />}
          {selectedSeatIcon}
          Selected
        </div>
      </div> : '' }
    </div>
  );
};
