import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Form, Button, FormControl, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import { createTransactionService, getCountriesService, getStatesService } from '../../services/transactionsService';
import { makeBookingPortalPaymentService } from '../../services/authorizeNetService';
import { RedAsterisk, roundTo, validateEmail } from '../../utils/helper';
import Select from 'react-select';
import _ from 'lodash';
import './index.scss';
import { createTicketsService, getTourTicketInfoSettingsService } from '../../services/toursServices';
import Loading from '../Loading';
import { getTourSpecialRequirementTypesService } from '../../services/TourSpecialRequirementTypesService';
import { getPassengerPointsService } from '../../services/passengerPointsService';
import { DatePicker } from 'react-tempusdominus-bootstrap';

import BillingPaymentInformation from '../BillingPaymentInformation';
import {
    calculateAmountToBeCharged,
    calculateSurchargeAmount,
    calculateTourTicketDepositCost,
} from '../../utils/ticketPriceCalculations';
import TicketPricingSummary from '../TicketPricingSummary';
import { SeatMap } from '../../SeatPicker/src/components/SeatMap/SeatMap';
import SelectSeatsForTicketPurchaseOrder from '../SelectSeatsForTicketPurchaseOrder';
import { getVehicleAssigmentSeatMappings } from '../../services/seatMappingsService';

const TourPayment = ({ tripSetting, ticketQuantities, ticketUpgrades, selectedTour, width, setFormState }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [ip, setIP] = useState('');
    const [tickets, setTickets] = useState([]);
    const [errors, setErrors] = useState([]);
    const [copyPassengerDetails, setCopyPassengerDetails] = useState(false);
    const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(0);
    const [tourSpecialRequirementTypes, setTourSpecialRequirementTypes] = useState([]);
    const [passengerPoints, setPassengerPoints] = useState([]);
    const [ticketInfoSettings, setTicketInfoSettings] = useState({});
    const [shouldCalculatePrices, setShouldCalculatePrices] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [selectedPaymentType, setSelectedPaymentType] = useState(
        selectedTour.initialPaymentTypeID !== 1 ? 'deposit' : 'full'
    );
    const [showSeatMapModal, setShowSeatMapModal] = useState(false);
    const [shouldSelectSeats, setShouldSelectSeats] = useState(selectedTour?.tourTicketSeatSelectionRequired);
    const [availableSeatMappings, setAvailableSeatMappings] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [seatError, setSeatError] = useState(null);
    const [refetchSeats, setRefetchSeats] = useState(false);

    useEffect(() => {
        const ticketDataSet = [];
        ticketQuantities.forEach((tq) => {
            [...Array(tq.selected)].forEach((t, i) => {
                ticketDataSet.push({
                    price: tq.price,
                    salesTax: tq.salesTax,
                    salesTaxID: tq.salesTaxID,
                    ticketType: tq.ticketType,
                    tourTicketID: tq.tourTicketID,
                    availableTicketID: tq.availableTicketID,
                    optionalUpgrades: [],
                    requiredUpgrades: [],
                    firstName: '',
                    lastName: '',
                    email: '',
                    homePhone: '',
                    otherPhone: '',
                    dateOfBirth: '',
                    addressStreet1: '',
                    addressStreet2: '',
                    addressCity: '',
                    addressState: '',
                    addressZip: '',
                    driverLicenseExpiry: '',
                    driverLicenseNumber: '',
                    passportExpiry: '',
                    passportNumber: '',
                    pickUpPassengerPointID: null,
                    dropOffPassengerPointID: null,
                    primaryContact: i === 0,
                    errors: [],
                    tourSpecialRequirementTypeID: null,
                });
            });
        });
        setTickets(ticketDataSet);
        setShouldCalculatePrices(!shouldCalculatePrices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const fetchedStates = await getStatesService();
                setStates(fetchedStates?.data);
                const fetchedCountries = await getCountriesService();
                setCountries(fetchedCountries?.data);
                const fetchedTourSpecialRequirementTypes = await getTourSpecialRequirementTypesService(
                    tripSetting.companyID
                );
                const { data: fetchedTicketInfoSettings } = await getTourTicketInfoSettingsService(selectedTour?.id);
                setTicketInfoSettings(fetchedTicketInfoSettings);
                const { data: fetchedPassengerPoints } = await getPassengerPointsService(selectedTour?.id);
                setPassengerPoints(fetchedPassengerPoints);
                setTourSpecialRequirementTypes(fetchedTourSpecialRequirementTypes?.data);
            } catch (e) {
                console.log('e', e);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (shouldSelectSeats) {
                    const { data: seatMappings } = await getVehicleAssigmentSeatMappings(selectedTour?.id);
                    if (!seatMappings.length) {
                        setShouldSelectSeats(false);
                    } else {
                        setAvailableSeatMappings(seatMappings);
                    }
                }
            } catch (e) {
                console.log('e', e);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchSeats]);

    useEffect(() => {
        const amountToBeCharged = calculateAmountToBeCharged({
            tickets,
            initialPaymentTypeID: selectedTour?.initialPaymentTypeID,
            ticketDepositAmount: selectedTour?.ticketDepositAmount,
            tripSetting,
            selectedPaymentType,
        });
        setPaymentAmount(amountToBeCharged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldCalculatePrices, selectedPaymentType]);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    };

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress();
    }, []);

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                // Check Ticket & Passenger Data
                if (!checkTicketsAreValid()) {
                    setLoading(false);
                    return;
                }

                // Check Payment Data
                const errorArr = [];
                if (!data.firstName) errorArr.push('firstName');
                if (!data.lastName) errorArr.push('lastName');
                if (!data.email) errorArr.push('email');
                if (!data.street) errorArr.push('street');
                if (!data.city) errorArr.push('city');
                if (!data.state) errorArr.push('state');
                if (!data.zip) errorArr.push('zip');
                if (!data.cardExpiration) errorArr.push('cardExpiration');
                if (!data.cardNumber) errorArr.push('cardNumber');
                if (!data.cvv) errorArr.push('cvv');
                setErrors([...errorArr]);

                if (!errorArr.length) {
                    if (data && data.cardNumber && data.cardNumber.length < 15) {
                        setLoading(false);
                        return swal({
                            title: 'Card Number Error',
                            text: 'Please enter a valid Card Number',
                            icon: 'error',
                        });
                    }

                    const trimTickets = tickets.map((t, index) => ({
                        ...t,
                        firstName: t.firstName.trim(),
                        lastName: t.lastName.trim(),
                        email: t.email.trim(),
                        ...(selectedSeats.length && { seatMappingSeatID: selectedSeats[index]?.seatId }),
                        ...(selectedSeats.length && { vehicleInventoryID: selectedSeats[index]?.vehicleInventoryID }),
                    }));

                    const ticketRequest = await createTicketsService({
                        ticketData: trimTickets,
                        vehicleAssignmentID: selectedTour?.id,
                        companyID: tripSetting.companyID,
                    });

                    const ticketResponse = ticketRequest?.data;

                    if (ticketResponse?.error) {
                        swal({
                            title: 'Tickets Claimed',
                            text: 'One or more of the seats you have selected have been claimed since beginning the checkout process. Please reselect your seats.',
                            icon: 'error',
                        });
                        setRefetchSeats(!refetchSeats);
                        setSelectedSeats([]);
                        setLoading(false);
                        return;
                    }

                    const surchargeAmount = calculateSurchargeAmount({
                        amount:
                            selectedPaymentType === 'deposit'
                                ? calculateTourTicketDepositCost(tickets, selectedTour)
                                : null,
                        tickets,
                        tripSetting,
                    });

                    const transactionRequestData = {
                        ...data,
                        ticketPurchaseOrderID: ticketResponse?.ticketPurchaseOrder?.id, // from ticketRequest
                        amount: paymentAmount,
                        surchargeAmount: surchargeAmount > 0 ? surchargeAmount : null,
                        transactionTypeID: 3,
                        companyID: tripSetting.companyID,
                        date: moment().format('YYYY-MM-DD'),
                        generalLedgerCodeID: tripSetting.defaultGeneralLedgerCodeID || null,
                    };
                    let transactionRequest = await createTransactionService(transactionRequestData);
                    const { transaction, surchargeTransaction = null } = transactionRequest.data;

                    const paymentRequestData = {
                        ...transactionRequestData,
                    };
                    paymentRequestData.companyID = tripSetting.companyID;
                    paymentRequestData.orderID = transaction.id;
                    paymentRequestData.ecomind = 'E';
                    paymentRequestData.customerID = ip;
                    if (surchargeTransaction) paymentRequestData.surchargeTransactionID = surchargeTransaction.id;

                    let response = await makeBookingPortalPaymentService(paymentRequestData, tripSetting.companyID);

                    response = response.data;
                    if (typeof response == 'undefined') {
                        window.swal(
                            'Transaction Failed',
                            "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.",
                            'error'
                        );
                        setLoading(false);
                        return;
                    } else if (response.errors && response.errors.length > 0) {
                        swal({ title: 'Transaction Declined', text: response.errors[0].msg, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.error) {
                        swal({ title: 'Transaction Declined', text: response.error, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.Result === 'Declined') {
                        swal({ title: 'Transaction Declined', text: response.Status, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.code === 'A') {
                        setFormState({
                            stage: 2,
                            id: transaction.id,
                            amount: transaction.amount,
                            emailSent: response.emailSent,
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                console.log('error: ', e);
                swal({
                    title: 'There was an error processing your tickets. Please try again.',
                    icon: 'error',
                });
                setLoading(false);
                return;
            }
        })();
    };

    const DatePickerComponent = ({ value, onChange }) => {
        return (
            <div>
                <DatePicker
                    autocomplete="off"
                    useCurrent={false}
                    date={value ? value : ''}
                    onChange={(e) => {
                        if (e?.date) onChange(e.date);
                    }}
                />
            </div>
        );
    };

    const handleEditTicket = (field, value, updateIndex) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) {
                if (field === 'primaryContact' && value) {
                    return { ...t, primaryContact: false };
                }
                return t;
            }
            return {
                ...t,
                [field]: value,
                errors: [],
            };
        });
        setErrors([]);
        setTickets(updatedTicketInfo);
    };

    const handleUpgradeSelect = (value, updateIndex, actionMeta = null) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) return t;

            if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
                return { ...t, optionalUpgrades: value, errors: [] };
            } else if (actionMeta.action === 'clear') {
                return { ...t, optionalUpgrades: [], errors: [] };
            }
        });

        setErrors([]);
        setTickets(updatedTicketInfo);
        setShouldCalculatePrices(!shouldCalculatePrices);
    };

    const handleRequiredUpgradeSelect = (value, updateIndex, actionMeta = null) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) return t;

            if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
                if (t?.requiredUpgrades?.length) {
                    return {
                        ...t,
                        requiredUpgrades: t.requiredUpgrades.map((tReqUpgrades) => {
                            if (tReqUpgrades.upgradeTypeID !== value.upgradeTypeID) return t;
                            else {
                                return value;
                            }
                        }),
                        errors: [],
                    };
                } else {
                    return {
                        ...t,
                        requiredUpgrades: [value],
                        errors: [],
                    };
                }
            } else if (actionMeta.action === 'clear') {
                return { ...t, requiredUpgrades: [], errors: [] };
            }
        });

        setErrors([]);
        setTickets(updatedTicketInfo);
        setShouldCalculatePrices(!shouldCalculatePrices);
    };

    const getRequiredUpgradesByUpgradeTypeID = (ticket) => {
        const requiredUpgradeMap = ticketUpgrades?.required?.filter(
            (rtu) => rtu.availableTicketID === ticket.availableTicketID
        );

        const requiredUpgradesByUpgradeTypeID = requiredUpgradeMap.reduce((accumulator, currVal) => {
            /* 
                This organizes the required upgrades into categories by their ID so we can create a dropdown for each.
                i.e. {
                    id: [...],
                    id: [...]
                }
            */
            currVal.label = `${currVal.availableTicketUpgrade} ${Number(currVal.price) > 0 ? `($${currVal.price})` : ''}`;
            currVal.value = currVal.availableTicketUpgradeID;
            if (accumulator[currVal?.upgradeTypeID]) accumulator[currVal?.upgradeTypeID].push(currVal);
            else accumulator[currVal?.upgradeTypeID] = [currVal];
            return accumulator;
        }, {});

        return requiredUpgradesByUpgradeTypeID;
    };

    const checkTicketsAreValid = () => {
        /* 
            1. There needs to be one main contact chosen.
            2. The main contact needs all personal info fields filled out.
            3. Secondary contacts just need first and last name.
            4. If there are required upgrades, all passengers need each req upgrade to be chosen.
            5. Proper email address regex (if not already there by default).
        */
        const ticketsClone = _.cloneDeep(tickets);

        if (shouldSelectSeats && selectedSeats.length !== ticketsClone.length) {
            setSeatError(
                `There are only ${selectedSeats.length} seat(s) selected and ${ticketsClone.length} tickets in the order.`
            );
            return false;
        }

        const mainContact = ticketsClone.find((t) => t.primaryContact);
        if (!mainContact) {
            // This is an error within the scope of the entire form.
            setErrors([...errors, 'mainContact']);
            return false;
        }

        if (!mainContact.email || !validateEmail(mainContact.email))
            mainContact.errors = [...mainContact.errors, 'email'];
        if (!mainContact.firstName) mainContact.errors = [...mainContact.errors, 'firstName'];
        if (!mainContact.lastName) mainContact.errors = [...mainContact.errors, 'lastName'];
        if (passengerPoints.length > 0) {
            if (!mainContact.pickUpPassengerPointID)
                mainContact.errors = [...mainContact.errors, 'pickUpPassengerPointID'];
            if (!mainContact.dropOffPassengerPointID)
                mainContact.errors = [...mainContact.errors, 'dropOffPassengerPointID'];
        }
        if (ticketInfoSettings?.mobile?.required && !mainContact.mobile) {
            mainContact.errors = [...mainContact.errors, 'mobile'];
        }
        if (ticketInfoSettings?.homePhone?.required && !mainContact.homePhone) {
            mainContact.errors = [...mainContact.errors, 'homePhone'];
        }
        if (ticketInfoSettings?.otherPhone?.required && !mainContact.otherPhone) {
            mainContact.errors = [...mainContact.errors, 'otherPhone'];
        }
        if (ticketInfoSettings?.driverLicenseNumber?.required) {
            if (!mainContact.driverLicenseExpiry) mainContact.errors = [...mainContact.errors, 'driverLicenseExpiry'];
            if (!mainContact.driverLicenseNumber) mainContact.errors = [...mainContact.errors, 'driverLicenseNumber'];
        }
        if (ticketInfoSettings?.passportNumber?.required) {
            if (!mainContact.passportExpiry) mainContact.errors = [...mainContact.errors, 'passportExpiry'];
            if (!mainContact.passportNumber) mainContact.errors = [...mainContact.errors, 'passportNumber'];
        }
        if (ticketInfoSettings?.dateOfBirth?.required && !mainContact.dateOfBirth) {
            mainContact.errors = [...mainContact.errors, 'dateOfBirth'];
        }
        if (ticketInfoSettings?.address?.required) {
            if (!mainContact.addressStreet1) mainContact.errors = [...mainContact.errors, 'addressStreet1'];
            if (!mainContact.addressCity) mainContact.errors = [...mainContact.errors, 'addressCity'];
            if (!mainContact.addressState) mainContact.errors = [...mainContact.errors, 'addressState'];
            if (!mainContact.addressZip) mainContact.errors = [...mainContact.errors, 'addressZip'];
        }

        const secondaryContacts = ticketsClone.filter((t) => !t.primaryContact);
        for (let contact of secondaryContacts) {
            if (!contact.firstName) contact.errors = [...contact.errors, 'firstName'];
            if (!contact.lastName) contact.errors = [...contact.errors, 'lastName'];
            if (contact.email && !validateEmail(contact.email)) [...contact.errors, 'email'];
            if (passengerPoints.length > 0) {
                if (!contact.pickUpPassengerPointID) contact.errors = [...contact.errors, 'pickUpPassengerPointID'];
                if (!contact.dropOffPassengerPointID) contact.errors = [...contact.errors, 'dropOffPassengerPointID'];
            }
            if (ticketInfoSettings?.mobile?.required && !contact.mobile) {
                contact.errors = [...contact.errors, 'mobile'];
            }
            if (ticketInfoSettings?.homePhone?.required && !contact.homePhone) {
                contact.errors = [...contact.errors, 'homePhone'];
            }
            if (ticketInfoSettings?.otherPhone?.required && !contact.otherPhone) {
                contact.errors = [...contact.errors, 'otherPhone'];
            }
            if (ticketInfoSettings?.driverLicenseNumber?.required) {
                if (!contact.driverLicenseExpiry) contact.errors = [...contact.errors, 'driverLicenseExpiry'];
                if (!contact.driverLicenseNumber) contact.errors = [...contact.errors, 'driverLicenseNumber'];
            }
            if (ticketInfoSettings?.passportNumber?.required) {
                if (!contact.passportExpiry) contact.errors = [...contact.errors, 'passportExpiry'];
                if (!contact.passportNumber) contact.errors = [...contact.errors, 'passportNumber'];
            }
            if (ticketInfoSettings?.dateOfBirth?.required && !contact.dateOfBirth) {
                contact.errors = [...contact.errors, 'dateOfBirth'];
            }
            if (ticketInfoSettings?.address?.required) {
                if (!contact.addressStreet1) contact.errors = [...contact.errors, 'addressStreet1'];
                if (!contact.addressCity) contact.errors = [...contact.errors, 'addressCity'];
                if (!contact.addressState) contact.errors = [...contact.errors, 'addressState'];
                if (!contact.addressZip) contact.errors = [...contact.errors, 'addressZip'];
            }
        }
        // Check if there are required upgrades in the first place
        if (ticketUpgrades?.required?.length) {
            for (let ticket of ticketsClone) {
                // Gather each different upgrade type ID (each unique ID = one dropdown)
                const reqUpgrades = getRequiredUpgradesByUpgradeTypeID(ticket);
                const reqUpgradeTypeIDs = Object.keys(reqUpgrades);

                for (let reqUpgradeTypeID of reqUpgradeTypeIDs) {
                    // If we can't find a req upgrade on the ticket that matches an upgradeTypeID, there is an error.
                    if (
                        !ticket?.requiredUpgrades.some((tReqUpgrade) => tReqUpgrade.upgradeTypeID !== reqUpgradeTypeID)
                    ) {
                        ticket.errors = [...ticket.errors, 'requiredUpgrades'];
                    }
                }
            }
        }

        if (ticketsClone.some((ticket) => ticket.errors?.length > 0)) {
            setTickets(ticketsClone);
            return false;
        }

        return true;
    };

    const handleCopyPassengerDetails = (e) => {
        setCopyPassengerDetails(!e?.target?.checked);
        if (e?.target?.checked) {
            const passenger = tickets.find((t) => t.primaryContact);
            if (passenger) {
                setValue('firstName', passenger.firstName);
                setValue('lastName', passenger.lastName);
                setValue('email', passenger.email);
                setValue('street', passenger.addressStreet1);
                setValue('street2', passenger.addressStreet2);
                setValue('city', passenger.addressCity);
                setValue('state', passenger.addressState);
                setValue('zip', passenger.addressZip);
            }
        }
    };

    const renderTicketUpgrades = (t, i) => {
        if (ticketUpgrades?.available?.find((tUp) => tUp.availableTicketID === t.availableTicketID)) {
            return (
                <Col sm={12} md={4} lg={4}>
                    <FormGroup>
                        <Form.Label>Upgrades</Form.Label>
                        <Select
                            name="upgrades"
                            value={t.optionalUpgrades}
                            options={ticketUpgrades?.available
                                ?.filter((tUp) => tUp.availableTicketID === t.availableTicketID)
                                .map((tu) => ({
                                    ...tu,
                                    label: `${tu.availableTicketUpgrade} ($${tu.price})`,
                                    value: tu.availableTicketUpgradeID,
                                }))}
                            onChange={(selectedOption, actionMeta) =>
                                handleUpgradeSelect(selectedOption, i, actionMeta)
                            }
                            isMulti
                            isClearable={true}
                        />
                    </FormGroup>
                </Col>
            );
        } else {
            return <></>;
        }
    };
    return loading ? (
        <Loading loading={loading} />
    ) : (
        <Row>
            <Col md={8} xs={12} className="d-flex justify-content-center">
                <div className="w-100 payment-form-main">
                    <form
                        className="payment-form-wrapper"
                        onSubmit={(e) => {
                            handleSubmitPayment(e);
                        }}
                    >
                        <Form.Label
                            className="text-uppercase payment-form-heading"
                            style={{ color: `${tripSetting.textColor}` }}
                        >
                            Ticket Passenger Information
                        </Form.Label>
                        <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            {tickets.map((t, i, ticketList) => {
                                const requiredUpgradesByUpgradeTypeID = getRequiredUpgradesByUpgradeTypeID(t);
                                return (
                                    <Row key={t.id} className="px-4">
                                        <Row className="w-100">
                                            <Col sm={12} md={12} lg={12}>
                                                <h5 style={{ color: `${tripSetting.textColor}` }}>{t.ticketType}</h5>
                                            </Col>
                                            <Row>
                                                <Row className="px-3 w-100">
                                                    <Col xs={12} md={3} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>First Name*</Form.Label>
                                                            <Form.Control
                                                                id="firstName"
                                                                name="firstName"
                                                                placeholder="First Name"
                                                                value={t.firstName}
                                                                onChange={(e) => {
                                                                    handleEditTicket('firstName', e?.target?.value, i);
                                                                }}
                                                                isInvalid={t?.errors?.includes('firstName')}
                                                                type="text"
                                                            />
                                                            <FormControl.Feedback id="firstName" type="invalid">
                                                                Please Enter First Name
                                                            </FormControl.Feedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={3} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>Last Name*</Form.Label>
                                                            <Form.Control
                                                                id="lastName"
                                                                placeholder="Last Name"
                                                                name="lastName"
                                                                value={t.lastName}
                                                                onChange={(e) => {
                                                                    handleEditTicket('lastName', e?.target?.value, i);
                                                                }}
                                                                isInvalid={t?.errors?.includes('lastName')}
                                                                type="text"
                                                            />
                                                            <FormControl.Feedback id="lastName" type="invalid">
                                                                Please Enter Last Name
                                                            </FormControl.Feedback>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col xs={12} md={3} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>{`Email Address${t.primaryContact ? '*' : ''}`}</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name={`email`}
                                                                value={t.email}
                                                                placeholder="Email Address"
                                                                isInvalid={t?.errors?.includes('email')}
                                                                onChange={(e) => {
                                                                    handleEditTicket('email', e?.target?.value, i);
                                                                }}
                                                            />
                                                            <FormControl.Feedback id="email" type="invalid">
                                                                Please Enter a Valid Email
                                                            </FormControl.Feedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="px-3 w-100">
                                                    {ticketInfoSettings['mobile']?.show && (
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    Mobile{' '}
                                                                    {ticketInfoSettings['mobile']?.required && (
                                                                        <RedAsterisk />
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`mobile`}
                                                                    name={`mobile`}
                                                                    value={t.mobile}
                                                                    placeholder="Mobile Number"
                                                                    isInvalid={t?.errors?.includes('mobile')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket('mobile', e?.target?.value, i);
                                                                    }}
                                                                />
                                                                <FormControl.Feedback id="mobile" type="invalid">
                                                                    Please Provide Mobile Number
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    {ticketInfoSettings['homePhone']?.show && (
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    Home Phone{' '}
                                                                    {ticketInfoSettings['homePhone']?.required && (
                                                                        <RedAsterisk />
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`homePhone`}
                                                                    name={`homePhone`}
                                                                    value={t.homePhone}
                                                                    placeholder="Home Number"
                                                                    isInvalid={t?.errors?.includes('homePhone')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'homePhone',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                                <FormControl.Feedback id="homePhone" type="invalid">
                                                                    Please Provide Home Number
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    {ticketInfoSettings['otherPhone']?.show && (
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    Other Phone{' '}
                                                                    {ticketInfoSettings['otherPhone']?.required && (
                                                                        <RedAsterisk />
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`otherPhone`}
                                                                    name={`otherPhone`}
                                                                    value={t.otherPhone}
                                                                    placeholder="Other Number"
                                                                    isInvalid={t?.errors?.includes('otherPhone')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'otherPhone',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                                <FormControl.Feedback id="otherPhone" type="invalid">
                                                                    Please Provide Other Number
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Row>
                                            {/* Address */}
                                            {ticketInfoSettings['address']?.show && (
                                                <Col xs={12} md={8} lg={8}>
                                                    <FormGroup>
                                                        <Form.Label>
                                                            Address{' '}
                                                            {ticketInfoSettings['address']?.required && <RedAsterisk />}
                                                        </Form.Label>
                                                        <Row className="no-gutters mx-0">
                                                            <Col xs={12} className={'mt-2'}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`addressStreet1`}
                                                                    id={`addressStreet1`}
                                                                    placeholder="Address 1"
                                                                    value={t.addressStreet1}
                                                                    isInvalid={t.errors?.includes('addressStreet1')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'addressStreet1',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} className="mt-2">
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`addressStreet2`}
                                                                    id={`addressStreet2`}
                                                                    placeholder="Address 2"
                                                                    value={t.addressStreet2}
                                                                    isInvalid={t.errors?.includes('addressStreet2')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'addressStreet2',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} className="mt-2">
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`addressCity`}
                                                                    id={`addressCity`}
                                                                    placeholder="City"
                                                                    value={t.addressCity}
                                                                    isInvalid={t.errors?.includes('addressCity')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'addressCity',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={7} className="mt-2">
                                                                <Form.Control
                                                                    as="select"
                                                                    id={`addressState`}
                                                                    name={`addressState`}
                                                                    value={t.addressState}
                                                                    isInvalid={t.errors?.includes('addressState')}
                                                                    placeholder="State"
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'addressState',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                >
                                                                    <option value="">State/Region</option>
                                                                    {states.length &&
                                                                        states.map((r, i) => (
                                                                            <option key={i} value={r.abbreviation}>
                                                                                {r.abbreviation}
                                                                            </option>
                                                                        ))}
                                                                </Form.Control>
                                                            </Col>
                                                            <Col xs={12} md={5} className="mt-2 pl-0 pl-md-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`addressZip`}
                                                                    name={`addressZip`}
                                                                    placeholder="Zip"
                                                                    value={t.addressZip}
                                                                    isInvalid={t.errors?.includes('addressZip')}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'addressZip',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <FormControl.Feedback id="address" type="invalid">
                                                            Please Provide Your Complete Address
                                                        </FormControl.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Row className="w-100">
                                                {Object.entries(requiredUpgradesByUpgradeTypeID).map(
                                                    ([key, value], j) => {
                                                        return (
                                                            <Col key={j} sm={12} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <Form.Label>
                                                                        Select {`${value[j]?.upgradeType}*`}
                                                                    </Form.Label>
                                                                    <Select
                                                                        name="requiredUpgrades"
                                                                        options={value}
                                                                        isClearable={true}
                                                                        onChange={(selectedOption, actionMeta) =>
                                                                            handleRequiredUpgradeSelect(
                                                                                selectedOption,
                                                                                i,
                                                                                actionMeta
                                                                            )
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                                {renderTicketUpgrades(t, i)}
                                                {tourSpecialRequirementTypes.length > 0 && (
                                                    <Col xs={12} md={4} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>Special Requirements</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                id="tourSpecialRequirementTypeID"
                                                                name="tourSpecialRequirementTypeID"
                                                                value={t.tourSpecialRequirementTypeID}
                                                                onChange={(e) => {
                                                                    handleEditTicket(
                                                                        'tourSpecialRequirementTypeID',
                                                                        e?.target?.value,
                                                                        i
                                                                    );
                                                                }}
                                                            >
                                                                <option value="" selected>
                                                                    No Selection
                                                                </option>
                                                                {tourSpecialRequirementTypes.map((r) => (
                                                                    <option key={r.id} value={r.id}>
                                                                        {`${r.name}`}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                                {passengerPoints.length > 0 &&
                                                    passengerPoints.filter((p) => p.isPickUp).length > 0 && (
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>Pickup Location*</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    id="pickUpPassengerPointID"
                                                                    name="pickUpPassengerPointID"
                                                                    value={t?.pickUpPassengerPointID}
                                                                    onChange={(selectedOption) => {
                                                                        handleEditTicket(
                                                                            'pickUpPassengerPointID',
                                                                            selectedOption?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                    isInvalid={t?.errors?.includes(
                                                                        'pickUpPassengerPointID'
                                                                    )}
                                                                >
                                                                    <option value="" selected>
                                                                        No Selection
                                                                    </option>
                                                                    {passengerPoints
                                                                        .filter((p) => p.isPickUp)
                                                                        .map((r) => (
                                                                            <option key={r.id} value={r.id}>
                                                                                {`${r.description}`}
                                                                            </option>
                                                                        ))}
                                                                </Form.Control>
                                                                <FormControl.Feedback
                                                                    id="pickUpPassengerPointID"
                                                                    type="invalid"
                                                                >
                                                                    Please select a pickup location
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                {passengerPoints.length > 0 &&
                                                    passengerPoints.filter((p) => p.isDropOff).length > 0 && (
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>Drop Off Location*</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    id="dropOffPassengerPointID"
                                                                    name="dropOffPassengerPointID"
                                                                    value={t.dropOffPassengerPointID}
                                                                    isInvalid={t?.errors?.includes(
                                                                        'dropOffPassengerPointID'
                                                                    )}
                                                                    onChange={(selectedOption) =>
                                                                        handleEditTicket(
                                                                            'dropOffPassengerPointID',
                                                                            selectedOption?.target?.value,
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    <option value="" selected>
                                                                        No Selection
                                                                    </option>
                                                                    {passengerPoints
                                                                        .filter((p) => p.isDropOff)
                                                                        .map((r) => (
                                                                            <option key={r.id} value={r.id}>
                                                                                {`${r.description}`}
                                                                            </option>
                                                                        ))}
                                                                </Form.Control>
                                                                <FormControl.Feedback
                                                                    id="dropOffPassengerPointID"
                                                                    type="invalid"
                                                                >
                                                                    Please select a drop off location
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                            </Row>
                                            {ticketInfoSettings['driverLicenseNumber']?.show && (
                                                <Row className="mt-3 w-100">
                                                    <Col xs={12} md={4} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>
                                                                Driver License Number{' '}
                                                                {ticketInfoSettings['driverLicenseNumber']
                                                                    ?.required && <RedAsterisk />}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                id={`driverLicenseNumber`}
                                                                name={`driverLicenseNumber`}
                                                                value={t.driverLicenseNumber}
                                                                placeholder="Driver License Number"
                                                                onChange={(e) => {
                                                                    handleEditTicket(
                                                                        'driverLicenseNumber',
                                                                        e?.target?.value,
                                                                        i
                                                                    );
                                                                }}
                                                                isInvalid={t.errors?.includes('driverLicenseNumber')}
                                                            />
                                                            <FormControl.Feedback
                                                                id="driverLicenseNumber"
                                                                type="invalid"
                                                            >
                                                                Please Provide Your Driver License Number
                                                            </FormControl.Feedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={4} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>
                                                                Driver License Expiration{' '}
                                                                {ticketInfoSettings['driverLicenseNumber']
                                                                    ?.required && <RedAsterisk />}
                                                            </Form.Label>

                                                            <DatePickerComponent
                                                                value={t.driverLicenseExpiry}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        handleEditTicket(
                                                                            'driverLicenseExpiry',
                                                                            date,
                                                                            i
                                                                        );
                                                                    }
                                                                }}
                                                                i={i}
                                                            />
                                                            {t.errors?.includes('driverLicenseExpiry') && (
                                                                <div className="text-danger">
                                                                    Please Provide Driver License Expiration Date
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row className="mt-3 w-100">
                                                {ticketInfoSettings['passportNumber']?.show && (
                                                    <>
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    Passport Number{' '}
                                                                    {ticketInfoSettings['passportNumber']?.required && (
                                                                        <RedAsterisk />
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`passportNumber`}
                                                                    name={`passportNumber`}
                                                                    isInvalid={t.errors?.includes('passportNumber')}
                                                                    value={t.passportNumber}
                                                                    onChange={(e) => {
                                                                        handleEditTicket(
                                                                            'passportNumber',
                                                                            e?.target?.value,
                                                                            i
                                                                        );
                                                                    }}
                                                                    placeholder="Passport Number"
                                                                />
                                                                <FormControl.Feedback
                                                                    id="passportNumber"
                                                                    type="invalid"
                                                                >
                                                                    Please Provide Your Passport Number
                                                                </FormControl.Feedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12} md={4} lg={4}>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    Passport Expiration{' '}
                                                                    {ticketInfoSettings['passportNumber']?.required && (
                                                                        <RedAsterisk />
                                                                    )}
                                                                </Form.Label>
                                                                <DatePickerComponent
                                                                    value={t.passportExpiry}
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            handleEditTicket('passportExpiry', date, i);
                                                                        }
                                                                    }}
                                                                    i={i}
                                                                />

                                                                {t.errors?.includes('passportExpiry') && (
                                                                    <div className="text-danger">
                                                                        Please Provide Passport Expiration Date
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                            <Row className="mt-3 w-100">
                                                {ticketInfoSettings['dateOfBirth']?.show && (
                                                    <Col xs={12} md={6} lg={6}>
                                                        <FormGroup className="my-2">
                                                            <Form.Label>
                                                                Date of Birth{' '}
                                                                {ticketInfoSettings['dateOfBirth']?.required && (
                                                                    <RedAsterisk />
                                                                )}
                                                            </Form.Label>
                                                            <DatePickerComponent
                                                                value={t.dateOfBirth}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        handleEditTicket('dateOfBirth', date, i);
                                                                    }
                                                                }}
                                                                i={i}
                                                            />
                                                            {t.errors?.includes('dateOfBirth') && (
                                                                <div className="text-danger">
                                                                    Please Provide Date of Birth
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Col xs={12} md={6} lg={6}>
                                                <Form.Check
                                                    className={`my-1 text-align-center`}
                                                    type="checkbox"
                                                    checked={selectedPrimaryContact === i}
                                                    name="mainContact"
                                                    label="Set as Primary Contact"
                                                    onChange={(e) => {
                                                        // The form coerces the boolean false to 'false', so need to check for that happening
                                                        const value = e.target.checked;
                                                        setSelectedPrimaryContact(i);
                                                        if (value) handleEditTicket('primaryContact', value, i);
                                                    }}
                                                    style={{
                                                        display: `${ticketList.length === 1 ? 'none' : 'block'}`,
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        {i !== ticketList.length - 1 && <hr className="hr-line" />}
                                    </Row>
                                );
                            })}
                            <p className="font-italic mt-1 mb-0">*Field Required</p>
                        </div>
                        {shouldSelectSeats ? (
                            <div>
                                <Form.Label
                                    className=" text-uppercase payment-form-heading"
                                    style={{ color: `${tripSetting.textColor}` }}
                                >
                                    SEAT RESERVATION
                                </Form.Label>
                                <Card
                                    className="d-flex flex-row justify-content-between align-items-center p-5"
                                    style={{
                                        backgroundColor: `${tripSetting.mainColor}`,
                                        height: '100px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowSeatMapModal(true);
                                        setSeatError('');
                                    }}
                                >
                                    <p
                                        className=" text-uppercase payment-form-heading"
                                        style={{
                                            color: `${tripSetting.textColor}`,
                                            fontSize: '18px',
                                        }}
                                    >
                                        Select your seat(s)
                                    </p>
                                    <div className="d-flex align-items-center">
                                        {selectedSeats.length ? (
                                            selectedSeats.map((ss, i, arr) => (
                                                <p
                                                    key={ss}
                                                    style={{
                                                        color: `${tripSetting.textColor}`,
                                                        margin: '0px 2px 0px 2px',
                                                    }}
                                                >
                                                    {ss.seatDisplayName}
                                                    {i !== arr.length - 1 ? ', ' : ''}
                                                </p>
                                            ))
                                        ) : (
                                            <p style={{ color: `${tripSetting.textColor}`, margin: '0px' }}>
                                                No Seats Selected
                                            </p>
                                        )}
                                        <i
                                            className="fas fa-arrow-right ml-2"
                                            style={{ color: `${tripSetting.textColor}` }}
                                        />
                                    </div>
                                </Card>
                                {seatError && (
                                    <div className="text-danger mt-2">
                                        <p>{seatError}</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                        <BillingPaymentInformation
                            register={register}
                            errors={errors}
                            setErrors={setErrors}
                            handleCopyPassengerDetails={handleCopyPassengerDetails}
                            copyPassengerDetails={copyPassengerDetails}
                            tripSetting={tripSetting}
                            states={states}
                            countries={countries}
                        />
                        <div>
                            <p className="payment-form-required-title text-white mt-2">*Field Required</p>
                        </div>
                        <div className="button-wrapper mt-3">
                            <div className="button-of-first">
                                <Button
                                    className="mb-2"
                                    disabled={tickets.some((ticket) => ticket?.errors?.length) || errors.length}
                                    onClick={handleSubmitPayment}
                                    style={{
                                        backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                        borderColor: 'transparent',
                                    }}
                                >
                                    <i className="fas fa-lock  mr-2 "></i> SUBMIT PAYMENT
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Col>
            <TicketPricingSummary
                tripSetting={tripSetting}
                selectedTour={selectedTour}
                tickets={tickets}
                width={width}
                selectedPaymentType={selectedPaymentType}
                setSelectedPaymentType={setSelectedPaymentType}
                paymentAmount={paymentAmount}
            />
            {showSeatMapModal && (
                <SelectSeatsForTicketPurchaseOrder
                    seatMappings={availableSeatMappings}
                    setSeatMappings={setAvailableSeatMappings}
                    selectedSeats={selectedSeats}
                    setSelectedSeats={setSelectedSeats}
                    ticketCount={tickets?.length}
                    tripSetting={tripSetting}
                    onClose={() => setShowSeatMapModal(false)}
                />
            )}
        </Row>
    );
};

export default TourPayment;
