import React, { useState } from 'react';
import { Card, ToggleButtonGroup, ToggleButton, Modal, Button } from 'react-bootstrap';
import { SeatMap } from '../../SeatPicker/src/components/SeatMap/SeatMap';

const SelectSeatsForTicketPurchaseOrder = ({
    seatMappings,
    setSeatMappings,
    selectedSeats,
    setSelectedSeats,
    ticketCount,
    onClose,
    tripSetting,
}) => {
    const [selectedSeatMapping, setSelectedSeatMapping] = useState(seatMappings[0]);

    const handleSelectSeat = (seat) => {
        if (seat.isReserved) return;
        const seatId = seat.seatId;

        const layoutToUpdate = _.cloneDeep(seatMappings);
        const mappingToUpdate = layoutToUpdate.find(
            (mapping) => mapping.vehicleInventoryID === selectedSeatMapping.vehicleInventoryID
        );
        let seatToUpdate = {};

        for (let area of mappingToUpdate.areas) {
            for (let grid of area.grids) {
                for (let seat of grid.seats) {
                    if (seatId === seat.seatId) {
                        seatToUpdate = seat;
                    }
                }
            }
        }

        if (seatToUpdate.isSelected) {
            seatToUpdate.isSelected = false;
            setSelectedSeats(selectedSeats.filter((selectedSeat) => selectedSeat.seatId !== seatId));
        } else if (selectedSeats.length >= ticketCount) {
            return;
        } else {
            seatToUpdate.isSelected = true;
            setSelectedSeats([
                ...selectedSeats,
                {
                    seatId,
                    vehicleInventoryID: selectedSeatMapping.vehicleInventoryID,
                    seatDisplayName: seatToUpdate.seatDisplayName,
                },
            ]);
        }

        setSelectedSeatMapping(mappingToUpdate);
        setSeatMappings(layoutToUpdate);
    };

    return (
        <Modal className="rounded warning-modal" animation={false} size="lg" show={true} onHide={onClose} centered>
            <Modal.Header className="text-white" style={{ backgroundColor: tripSetting.textColor }}>
                <Modal.Title>SEAT SELECTION</Modal.Title>
                {<i className="fas fa-times text-white closeButton" style={{ cursor: 'pointer' }} onClick={onClose} />}
            </Modal.Header>
            <Modal.Body>
                <Card className="d-flex flex-column m-2 p-2">
                    <div style={{ marginBottom: '5px' }}>
                        <ToggleButtonGroup
                            type="radio"
                            name="options"
                            defaultValue={selectedSeatMapping?.vehicleInventoryID}
                        >
                            {seatMappings.map((seatMapping) => (
                                <ToggleButton
                                    id={seatMapping.vehicleInventoryID}
                                    value={seatMapping.vehicleInventoryID}
                                    onClick={() => setSelectedSeatMapping(seatMapping)}
                                    key={seatMapping.vehicleInventoryID}
                                    style={{
                                        borderRadius: '.25rem',
                                        marginRight: '5px',
                                        backgroundColor: tripSetting.buttonColor,
                                    }}
                                    className={
                                        selectedSeatMapping.vehicleInventoryID === seatMapping.vehicleInventoryID
                                            ? 'focus'
                                            : ''
                                    }
                                >
                                    {`${seatMapping.seatMappingName} - (Vehicle ${seatMapping.vehicleId})`}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    <Card className="d-flex">
                        <p
                            style={{ margin: '24px 0px 0px 24px' }}
                        >{`Seats selected: ${selectedSeats.length}/${ticketCount}`}</p>
                        <SeatMap
                            handleSeatClick={handleSelectSeat}
                            seatMapLegendPostion="bottom"
                            layout={selectedSeatMapping}
                        />
                    </Card>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{
                        backgroundColor: tripSetting.buttonColor,
                    }}
                    onClick={onClose}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectSeatsForTicketPurchaseOrder;
