import { Tooltip as ReactTooltip } from "react-tooltip";
import { DriverSeat } from "./DriverSeat";
import { SeatIcon } from "./SeatIcon";
import { TSeat } from "./SeatMap.type";

interface SeatProps {
  seat: TSeat;
  handleSeatClick?: Function;
  selectedSeatIds?: Array<number | string>;
  seatIcon?: JSX.Element;
  selectedSeatIcon?: JSX.Element;
  reservedSeatIcon?: JSX.Element;
  driverSeatIcon?: JSX.Element;
}

export const Seat = ({
  seat,
  handleSeatClick,
  selectedSeatIds,
  seatIcon,
  selectedSeatIcon,
  reservedSeatIcon,
  driverSeatIcon,
}: SeatProps) => {
  if (seat?.isDriverSeat)
    return driverSeatIcon ? driverSeatIcon : <DriverSeat />;

  let classStatus = "";

  if ((selectedSeatIds && selectedSeatIds.includes(seat.seatId)) || seat.isSelected) {
    classStatus = "selected";
  }

  if (seat.isReserved) {
    classStatus = "reserved";
  }

  return (
    <div
      key={seat.seatId}
      className={`seat-item ${classStatus}`}
      onClick={() => handleSeatClick && handleSeatClick(seat)}
      data-tooltip-id={`tooltip-${seat.seatId}`}
    >
      {!seatIcon && !selectedSeatIcon && !reservedSeatIcon && (
        <SeatIcon state={classStatus} label={seat.seatDisplayName} />
      )}
      {!["selected", "reserved"].includes(classStatus) && seatIcon}
      {classStatus === "selected" && selectedSeatIcon}
      {classStatus === "reserved" && reservedSeatIcon}
      {!seat?.isReserved && seat?.seatDisplayName && seat?.price && (
        <ReactTooltip
          id={`tooltip-${seat.seatId}`}
          place="top"
          variant="dark"
          content={`Seat number: ${seat.seatDisplayName} - Fare: $${Number(
            seat.price
          ).toFixed(2)}`}
        />
      )}
    </div>
  );
};
