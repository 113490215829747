export type TSeatMap = {
  totalArea: number;
  maxSeatPerRow: number;
  areas: TSeatMapArea[];
};

export type TSeatMapArea = {
  areaId: number | string;
  areaName?: string;
  grids: TSeatMapGrid[];
};

export type TSeatMapGrid = {
  gridRowId: number;
  seats: TSeat[];
};

export type TSeat = {
  gridSeatNum: number;
  seatId: number | string;
  seatDisplayName?: string;
  isDriverSeat?: boolean;
  isReserved?: boolean;
  price?: number;
  isEmpty?: boolean;
  isSelected?: boolean;
};

export enum EnumSeatMapLegendPosition {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
  HIDE = "hide",
}

export type TSeatMapProps = {
  layout: TSeatMap;
  handleSeatClick?: Function;
  selectedSeatIds?: Array<number | string>;
  seatIcon?: JSX.Element;
  selectedSeatIcon?: JSX.Element;
  reservedSeatIcon?: JSX.Element;
  driverSeatIcon?: JSX.Element;
  seatMapLegendPostion?: EnumSeatMapLegendPosition;
};
