import { Redirect, Route, Switch } from 'react-router-dom';
import { useReducer, useEffect } from 'react';

// Routes
import MockupRoutes from './MockupRoutes';
import PublicRoute from './publicRoute';

import Login from '../Containers/views/Login';
import { AuthReducer, initialStateAuth } from '../Context/reducer/authReducer';
import { AuthStateContext } from '../Context';
import ForgotPassword from '../Containers/views/ForgotPassword/index';
// import Activation from "../Containers/views/Activation";
import BookingTransaction from '../Components/BookingTransaction';
import BookingConfirmation from '../Components/BookingConfirmation';
import InvoicePayment from '../Components/InvoicePayment';
import BookingDesclaimer from '../Components/BookingDisclaimer';
import _ from 'lodash';
import TourLanding from '../Components/TourLanding';
import TourPaymentLanding from '../Components/TourPaymentLanding';

const Routes = () => {
    const [authUser, authDispatch] = useReducer(AuthReducer, initialStateAuth);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';

    const isAuthUser = () => {
        return Boolean(localStorage.getItem('token'));
    };
    useEffect(() => {
        let publicRouteRegex = /\/p\//gim;
        if (!publicRouteRegex.exec(window.location.href)) {
            // only reseting user auth if it's coming from routes not containing /p/
            if (localStorage.getItem('user')) {
                if (!sessionStorage.getItem('lastTimeStamp')) {
                    localStorage.clear();
                    window.location.href = '/auth/login';
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Switch>
            {/* "p" stands for public routes that don't need auth, can accessed from externally like from email */}
            <Route path="/p/booking-payment" exact component={BookingTransaction} />
            <Route path="/p/booking-confirmation" exact component={BookingConfirmation} />
            <Route path="/p/booking-desclaimer" exact component={BookingDesclaimer} />
            <Route path="/p/pay-invoice" exact component={InvoicePayment} />
            <Route path="/p/tours/:tourRouteID" exact component={TourLanding} />
            <Route path="/p/tourPayment/:ticketPurchaseOrderID" exact component={TourPaymentLanding} />
            <AuthStateContext.Provider value={{ authUser, authDispatch }}>
                <PublicRoute path="/" exact component={() => <Redirect to="/auth/login" />} />
                <PublicRoute path="/auth/login" exact component={Login} />
                <PublicRoute path="/auth/forgot-password" exact component={ForgotPassword} />
                {/* check if user is logged in then render mockup routes else redirect to login */}
                {(isAuthUser() || !_.isEmpty(authUser.user)) && <MockupRoutes />}
            </AuthStateContext.Provider>
            {/*default route when no route is matched */}
            <Route component={() => <Redirect to="/" />} />
        </Switch>
    );
};

export default Routes;
